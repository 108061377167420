.messageWrapper {
  padding: 0 !important;
  font-family: inherit !important;
  font-size: 1rem;
}

.messageBody {
  padding-left: 1rem !important;
  padding-right: 0.5rem !important;
  font-family: inherit !important;
}
