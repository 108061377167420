.buttonGroup {
  display: flex;
  position: absolute;
  width: 0;
  right: 0;
  top: 1rem;
  bottom: 0;
  z-index: 1;
  justify-content: flex-end;
  margin-bottom: 1rem;
  flex-wrap: nowrap;
  align-items: center;
  visibility: hidden;
  max-width: 0;
  & .button {
    margin: 0.25rem;
    background-color: #fafafa;
  }
}

.toolbar {
  width: 80px;
  height: 100%;
  margin: 0 auto;
}
