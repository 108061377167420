.phone {
  background-image: url(/phone.png);
  background-repeat: no-repeat;
  width: 252px;
  height: 510px;
  position: relative;
  color: #fff;
  background-size: 100% auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.phoneMessage {
  max-height: calc(100% - 170px);
  border-radius: 5px;
  overflow: auto;
  bottom: auto;
  background-color: #d8d8d8;
  width: 205px;
  padding-bottom: 10px;
  margin-bottom: auto;
  p {
    font-size: 0.8rem;
    padding: 7px;
    color: black;
    min-height: 15px;
    overflow: hidden;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}
.phoneHeader {
  width: 222px;
  text-align: center;
  color: #c1c1c1;
  font-weight: bold;
  margin-top: 65px;
  margin-bottom: 10px;
  line-height: 25px;
  border-bottom: solid 1px #d8d8d8;
}
.phoneCount {
  margin-bottom: 70px;
  width: 205px;
  border-radius: 5px;
  padding: 7px;
  color: #333;
  margin-top: 10px;
}
