.cursorPointer {
  cursor: pointer;
}

.burgerMenu {
  margin-left: auto;
  @media only screen and (min-width: 1024px) {
    display: none !important;
  }
}
