.cardContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;

  .card {
    width: 100%;
    height: 100%;
    max-width: 350px;
    cursor: pointer;
    &:hover {
      transition: all 0.3s ease-in-out;
      box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
    }
  }

  &.hasSelection {
    .card {
      cursor: default;
      &:not(.selected) {
        opacity: 0.2;
      }
    }
  }
}

.disabledOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-weight: bold;
}
