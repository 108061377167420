.tableRowCampaign {
  position: relative;
  z-index: 0;
  &:hover {
    [data-hover-flex] {
      -webkit-animation: slide-left 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: slide-left 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    }
    [data-hover-hide] {
      display: none;
    }
    &::after {
      content: "";
      width: 25%;
      height: 50px;
      background: linear-gradient(
        90deg,
        rgba(250, 250, 250, 0.6) 0%,
        rgba(250, 250, 250, 1) 30%,
        rgba(250, 250, 250, 1) 100%
      );
      display: block;
      position: absolute;
      right: 0;
    }
  }
}

.toolbar {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
}

.tableCell {
  padding: 0.5rem !important;
  height: 50px;
  text-align: center;
  position: relative;
}

.fade {
  -webkit-animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  animation: fade-in 0.2s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-3-23 13:30:44
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation fade-in
 * ----------------------------------------
 */
@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    visibility: hidden;
    max-width: 0;
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    visibility: visible;
    max-width: 100%;
  }
}
@keyframes slide-left {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    visibility: hidden;
    max-width: 0;
  }
  100% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
    visibility: visible;
    max-width: 100%;
  }
}
